@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'react-toastify/dist/ReactToastify.css';

:root {
  --primary: #262626;
  --indigo: #7b18f5;
  --white: #ffffff;
  --text-gray: #79747E;
  --main-bg: #f6f6f6;
  --text-light-gray: #6d6d6d;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  /* font-size: 14px;
  font-weight: 700; */
  box-sizing: border-box;
  background-color: #f5f5f5 !important;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiTypography-root .MuiDialogTitle-root {
  padding: 0;
}
