.paper {
  border-radius: 20px;
}

.modal {
  padding: 40px 24px 24px;
  position: relative;
  box-sizing: border-box;
  color: #000000;
  max-width: 920px;
  text-align: center;
}
